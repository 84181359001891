import styled from '@emotion/styled';
import { DocumentType, MediaType } from '@innedit/innedit-type';
import { MediaData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import Media from '~/components/Media';
import ListCard from '~/containers/Espace/Card';
import { ListItemProps } from '~/containers/Espace/List/props';
import ImagePhoto from '~/images/Photo';
import colors from '~/styles/colors';

const BodyCard = styled.div`
  em {
    color: ${colors.secondary[500]};
    font-weight: 600;
  }
`;

const ListCardMedia: FC<ListItemProps<MediaType, MediaData>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<MediaType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return (
      <li className="flex flex-col rounded !p-0">
        <div className="relative flex flex-auto flex-col-reverse bg-white-50 pb-full">
          <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
            <ImagePhoto color={colors.light[600]} size={100} />
          </div>
        </div>
      </li>
    );
  }

  // let photo;
  // try {
  //   if (doc.fullPath) {
  //     if (['audio/', 'video/'].includes(doc.type.slice(0, 'audio/'.length))) {
  //       photo = nextPhoto;
  //     } else {
  //       switch (doc.type) {
  //         case 'image/jpeg':
  //         case 'image/png':
  //         case 'image/webp': {
  //           photo = `${String(process.env.GATSBY_IMAGES_DOMAIN_NAME)}/${
  //             doc.fullPath
  //           }?w=320&h=320&c=max`;
  //           break;
  //         }
  //
  //         case 'image/svg+xml':
  //         default: {
  //           photo = `${String(process.env.GATSBY_IMAGES_DOMAIN_NAME)}/${
  //             doc.fullPath
  //           }`;
  //           break;
  //         }
  //       }
  //     }
  //   } else {
  //     photo = nextPhoto;
  //   }
  // } catch (e) {
  //   photo = nextPhoto;
  // }

  const handleOnClick = (event: SyntheticEvent<HTMLElement>) => {
    onClick(event);
  };

  return (
    <ListCard
      displayEdit={false}
      displayId={false}
      doc={doc}
      index={index}
      onClick={handleOnClick}
    >
      <Media
        media={{
          ...doc,
          origin: String(process.env.GATSBY_IMAGES_DOMAIN_NAME),
          pathname: doc.fullPath,
        }}
        options={{
          crop: 'contain',
        }}
        thumbnail
      />
      {/* <figure */}
      {/*  className="card-image aspect-ratio aspect-ratio--square" */}
      {/*  data-id={doc.id} */}
      {/*  onClick={handleOnClick} */}
      {/*  role="presentation" */}
      {/* > */}
      {/*  {photo && ( */}
      {/*    <img alt="Chargement" className="aspect-ratio__content" src={photo} /> */}
      {/*  )} */}
      {/* </figure> */}
      <BodyCard className="text-sm px-2  py-0.5">
        <h2 className="truncate text-center">{doc.type}</h2>
      </BodyCard>
    </ListCard>
  );
};

export default ListCardMedia;
